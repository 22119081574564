// src/modals/InviteCollaboratorsContent.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  collaboratorsInviteService,
  collaboratorsUpdateService,
  sheetsGetSheetService,
  userGetAccountService,
} from "../../services/apiServices.js";

const InviteCollaboratorsContent = ({ sheetId, sheetTitle }) => {
  const [collaborators, setCollaborators] = useState([
    { firstName: "", phoneNumber: "", phoneError: "" },
  ]);
  const [invitees, setInvitees] = useState([]);
  const [loadingInvitees, setLoadingInvitees] = useState(false);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [error, setError] = useState(null);
  const [inviteError, setInviteError] = useState(null);
  const [success, setSuccess] = useState(null);
  // Removed inviteMessage related states
  const [userFirstName, setUserFirstName] = useState(""); // Store user's first name
  const [userLastName, setUserLastName] = useState(""); // Store user's last name
  const [businessName, setBusinessName] = useState(""); // Store business name
  const [collaboratorLimit, setCollaboratorLimit] = useState(0); // New state for collaborator limit
  const [collaboratorCount, setCollaboratorCount] = useState(0); // New state for current count

  // Fetch user details when the component mounts
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const user = await userGetAccountService();
        setUserFirstName(user.firstName);
        setUserLastName(user.lastName);
        setBusinessName(user.businessName || ""); // Ensure businessName is fetched
        setCollaboratorLimit(user.collaboratorLimit || 0);
        setCollaboratorCount(user.collaboratorCount || 0);
        // Removed setting inviteMessage
      } catch (error) {
        console.error("Failed to fetch user details:", error);
        setError("Failed to fetch user details.");
      }
    };

    fetchUserDetails();
  }, [sheetTitle]);

  // Fetch existing invitees when component mounts or sheetId changes
  useEffect(() => {
    if (sheetId) {
      fetchInvitees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetId]);

  const fetchInvitees = async () => {
    setLoadingInvitees(true);
    try {
      const data = await sheetsGetSheetService(sheetId);
      setInvitees(data.sheet.invitedCollaborators || []);
      setCollaboratorCount(data.collaboratorCount || 0);
      setError(null);
    } catch (err) {
      setError(err.message || "Failed to load invitees.");
    } finally {
      setLoadingInvitees(false);
    }
  };

  // Handle adding a new collaborator section
  const handleAddCollaborator = () => {
    const availableSeats = collaboratorLimit - collaboratorCount;
    if (collaborators.length < availableSeats) {
      setCollaborators([
        ...collaborators,
        { firstName: "", phoneNumber: "", phoneError: "" },
      ]);
    } else {
      // Optionally, provide feedback to the user
      setInviteError(
        `You can only add up to ${availableSeats} collaborator(s).`
      );
    }
  };

  // Handle removing a collaborator section
  const handleRemoveCollaborator = (index) => {
    const newCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(newCollaborators);
  };

  // Handle input changes for text fields
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newCollaborators = collaborators.map((collaborator, i) =>
      i === index ? { ...collaborator, [name]: value } : collaborator
    );
    setCollaborators(newCollaborators);
  };

  // Handle phone number changes
  const handlePhoneChange = (index, value) => {
    const newCollaborators = collaborators.map((collaborator, i) =>
      i === index
        ? { ...collaborator, phoneNumber: value, phoneError: "" }
        : collaborator
    );
    setCollaborators(newCollaborators);
  };

  // Validate phone numbers
  const validatePhones = () => {
    let isValid = true;
    const newCollaborators = collaborators.map((collaborator) => {
      if (!isValidPhoneNumber(collaborator.phoneNumber || "")) {
        isValid = false;
        return { ...collaborator, phoneError: "Invalid phone number" };
      }
      return { ...collaborator, phoneError: "" };
    });
    setCollaborators(newCollaborators);
    return isValid;
  };

  // Removed invite message validation

  // Handle inviting collaborators
  const handleInvite = async () => {
    setInviteError(null);
    setSuccess(null);
    const isPhonesValid = validatePhones();
    // Removed inviteMessage validation

    if (isPhonesValid) {
      // Check if adding the new collaborators would exceed the limit
      const availableSeats = collaboratorLimit - collaboratorCount;
      if (availableSeats <= 0) {
        setInviteError("You have reached your collaborator seat limit.");
        return;
      }

      // Calculate how many new collaborators are being added
      const newCollaboratorsToAdd = collaborators.filter(
        (collab) => collab.phoneNumber
      ).length;
      if (newCollaboratorsToAdd > availableSeats) {
        setInviteError(
          `You can only invite ${availableSeats} more collaborator(s).`
        );
        return;
      }

      setLoadingInvite(true);
      try {
        // Extract phoneNumbers from collaborators
        const phoneNumbers = collaborators
          .map((collaborator) => collaborator.phoneNumber)
          .filter(Boolean); // Remove empty strings

        const response = await collaboratorsInviteService(
          sheetId,
          phoneNumbers
        );
        setSuccess("Collaborators invited successfully!");
        // Refresh the invitees list
        fetchInvitees();
        // Update local seat counts
        setCollaboratorCount(response.collaboratorCount || collaboratorCount);
        // Reset the form after successful invitation
        setCollaborators([{ firstName: "", phoneNumber: "", phoneError: "" }]);
        // No need to reset inviteMessage
      } catch (err) {
        setInviteError(err.message || "Failed to invite collaborators.");
      } finally {
        setLoadingInvite(false);
      }
    } else {
      setInviteError("Please fix the errors before inviting.");
    }
  };

  // Handle removing an invitee
  const handleRemoveInvitee = async (invitee) => {
    setError(null);
    setSuccess(null);
    try {
      // Create a new list of invitees excluding the removed invitee
      const updatedInvitees = invitees.filter((inv) => inv !== invitee);

      // Use collaboratorsUpdateService to send the updated invitees array
      const response = await collaboratorsUpdateService(
        sheetId,
        updatedInvitees
      );
      setSuccess(
        "Invitee removed successfully! They will not have access or reference to this sheet on Paia anymore."
      );
      setCollaboratorCount(response.collaboratorCount || collaboratorCount);
      setCollaboratorLimit(response.collaboratorLimit || collaboratorLimit);
      // Refresh the invitees list after update
      fetchInvitees();
    } catch (err) {
      setError(err.message || "Failed to remove invitee.");
    }
  };

  // Removed inviteMessage related variables
  // const placeholderInviteMessage = `You've been invited to log data to "${sheetTitle}" by ${userFirstName} ${userLastName}.`;

  // Calculate available seats
  const availableSeats = collaboratorLimit - collaboratorCount;
  const canAddCollaborator = collaborators.length < availableSeats;

  return (
    <div className="py-4 space-y-6">
      <h2 className="text-lg font-medium text-gray-700 mb-4">
        Invite others to log data to "{sheetTitle}". Paia will send WhatsApp
        messages to your invitees. Your invitees will not have view access to
        the sheet, but will be able to log data to it through WhatsApp.
      </h2>

      {/* Display Collaborator Seat Information */}
      <div className="mb-4 p-4 bg-green-100 rounded-md">
        <p className="text-sm text-green-700">
          You have <strong>{availableSeats}</strong> collaborator seat
          {availableSeats !== 1 ? "s" : ""} remaining out of{" "}
          <strong>{collaboratorLimit}</strong>. To add more visit "Manage
          Billing".
        </p>
      </div>

      <hr className="my-6 border-gray-300" />

      {/* Collaborator Forms */}
      {collaborators.map((collaborator, index) => (
        <div key={index} className="space-y-4 relative">
          {/* Remove Collaborator Button */}
          {collaborators.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemoveCollaborator(index)}
              className="absolute text-sm top-[-4px] right-0 text-red-500 hover:text-red-700"
              aria-label="Remove Collaborator"
            >
              &#10005;
            </button>
          )}

          {/* First Name */}
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="flex-1">
              <label
                htmlFor={`firstName-${index}`}
                className="block text-sm font-medium text-gray-700"
              >
                Collaborator First Name
              </label>
              <input
                id={`firstName-${index}`}
                type="text"
                name="firstName"
                value={collaborator.firstName}
                onChange={(e) => handleChange(index, e)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="John"
              />
            </div>
          </div>

          {/* Phone Number with Country Code */}
          <div>
            <label
              htmlFor={`phoneNumber-${index}`}
              className="block text-sm font-medium text-gray-700"
            >
              Collaborator's WhatsApp Number
            </label>
            <PhoneInput
              id={`phoneNumber-${index}`}
              international
              defaultCountry="ZA"
              value={collaborator.phoneNumber}
              onChange={(value) => handlePhoneChange(index, value)}
              className="phone-input-custom"
              placeholder="+1234567890"
            />

            {collaborator.phoneError && (
              <p className="text-sm text-red-500 mt-1">
                {collaborator.phoneError}
              </p>
            )}
          </div>
        </div>
      ))}

      {/* Add Collaborator Button */}
      <button
        type="button"
        onClick={handleAddCollaborator}
        disabled={!canAddCollaborator}
        className={`flex items-center text-sm text-blue-500 hover:underline ${
          !canAddCollaborator ? "opacity-50 cursor-not-allowed" : ""
        }`}
        title={
          !canAddCollaborator
            ? "You have reached the maximum number of collaborators."
            : "Add more collaborators"
        }
      >
        <span className="mr-1">+</span> Add more collaborators
      </button>

      {/* Invite Message Input - Removed */}
      {/* If you still want to allow custom messages, you can keep it. Otherwise, remove the entire block. */}

      {/* Invite Collaborators Button */}
      <div className="flex justify-between mt-6">
        <button
          onClick={handleInvite}
          disabled={loadingInvite || availableSeats <= 0}
          className={`bg-[#3D4934] text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 ${
            loadingInvite || availableSeats <= 0
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-[#2c362a]"
          }`}
        >
          {loadingInvite ? "Inviting..." : "Invite Collaborators"}
        </button>
      </div>

      {/* Display Errors and Success Messages */}

      {success && (
        <div
          className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-md"
          role="alert"
        >
          {success}
        </div>
      )}
      {error && (
        <div
          className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-md"
          role="alert"
        >
          {error}
        </div>
      )}
      {inviteError && (
        <div
          className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-md"
          role="alert"
        >
          {inviteError}
        </div>
      )}

      {/* Divider */}
      <hr className="my-6 border-gray-300" />

      {/* Invitees Section */}
      <div>
        <h3 className="text-md font-medium text-gray-700 mb-4">
          Invited Collaborators
        </h3>

        {loadingInvitees ? (
          <p className="text-gray-600">Loading invitees...</p>
        ) : invitees.length === 0 ? (
          <p className="text-gray-600">
            No collaborators have been invited yet.
          </p>
        ) : (
          <ul className="space-y-2">
            {invitees.map((invitee, index) => (
              <li
                key={index}
                className="flex items-center justify-between border border-gray-200 rounded-md p-3"
              >
                <span className="text-gray-700">{invitee}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveInvitee(invitee)}
                  className="text-red-500 hover:text-red-700"
                  aria-label={`Remove ${invitee}`}
                >
                  &#10005;
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

InviteCollaboratorsContent.propTypes = {
  sheetId: PropTypes.string.isRequired,
  sheetTitle: PropTypes.string.isRequired,
};

export default InviteCollaboratorsContent;
