// src/Dashboard.js
import React from "react";
import AccountConfig from "../components/common/AccountConfig.js";
import SheetsWrapper from "../components/sheets/SheetsWrapper.js";
import PaiaLogo from "../assets/paia-logo.svg";
import { ReactComponent as OpenIcon } from "../assets/open.svg";
import { useModal } from "../contexts/ModalContext.js";
import LoginLinkContent from "../components/modals/LoginLinkContent.js";

const Dashboard = ({ appUser }) => {
  const { openModal, closeModal } = useModal();

  return (
    <div className="min-h-screen">
      <header className="flex items-center justify-between p-4 py-8 md:p-12 md:pt-14 bg-white">
        <div className="flex items-center">
          <img src={PaiaLogo} alt="Paia Logo" className="h-11" />
        </div>
        <button
          className="flex items-center p-2 px-4 rounded-lg md:hidden border-2"
          onClick={() =>
            openModal(
              <div className="flex items-center">
                <OpenIcon className="w-6 h-6 mr-4" />
                <h2 className="text-xl font-semibold">Open on Laptop</h2>
              </div>,
              <LoginLinkContent onClose={closeModal} />
            )
          }
        >
          Open on Laptop
          <OpenIcon className="w-5 h-5 ml-2" />
        </button>
        <AccountConfig appUser={appUser} />
      </header>

      <main>
        <SheetsWrapper />
      </main>
    </div>
  );
};

export default Dashboard;
