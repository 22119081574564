import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalProvider } from "./contexts/ModalContext.js";
import PaiaLogo from "./assets/paia-logo.svg";
import Welcome from "./pages/Welcome.js";
import Dashboard from "./pages/Dashboard.js"; // Import Dashboard component
import {
  userGetAccountService,
  authStatusService,
  authVerifyService,
} from "./services/apiServices.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AppContent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [appUser, setAppUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserSession = async () => {
      try {
        setIsLoading(true); // Start loading
        const params = new URLSearchParams(location.search);
        const token = params.get("token");

        if (token) {
          // If a token is present, verify the login link
          const response = await authVerifyService(token);

          if (response.isAuthenticated) {
            setIsAuthenticated(true);
            setAppUser(response.user);

            // Only redirect to the dashboard if successfully authenticated
            navigate("/dashboard", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        } else {
          // If no token present, check for an existing session
          const sessionData = await authStatusService();
          setIsAuthenticated(sessionData.isAuthenticated);

          if (sessionData.isAuthenticated) {
            const userData = await userGetAccountService();
            setAppUser(userData);
          } else {
            setIsAuthenticated(false);
          }
        }
      } catch (error) {
        console.error("Session check or token verification failure:", error);
        setIsAuthenticated(false);
        setAppUser(null);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    checkUserSession();
  }, [location, navigate]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-white">
        <img src={PaiaLogo} alt="Paia Logo" className="h-20 animate-pulse" />
      </div>
    );
  }

  // Render the Dashboard if authenticated, otherwise show the Welcome screen
  return isAuthenticated ? <Dashboard appUser={appUser} /> : <Welcome />;
};

const App = () => (
  <Elements stripe={stripePromise}>
    <ModalProvider>
      <AppContent />
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        icon={false}
      />
    </ModalProvider>{" "}
  </Elements>
);

export default App;
