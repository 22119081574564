// src/components/sheets/SheetsWrapper.js

import React, { useState, useEffect, useCallback } from "react";
import SheetTable from "./SheetTable.js";
import SheetsTabs from "./SheetsTabs.js";
import SheetHeader from "./SheetHeader.js";
import { toast } from "react-toastify";
import {
  sheetsGetAllService,
  sheetsCreateSheetService,
  sheetAssistantService,
} from "../../services/apiServices.js";
import IconButton from "../common/IconButton.js";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import AiInput from "../common/AiInput.js"; // Import AiInput
import AiPanel from "../common/AiPanel.js"; // Import AiPanel

const SheetsWrapper = () => {
  const [sheets, setSheets] = useState([]);
  const [activeSheetId, setActiveSheetId] = useState(null);

  // State and functions for AiInput and AiPanel
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [aiMessage, setAiMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");

  // Function to handle sending messages to the assistant
  const handleSend = async ({ sheetId, message, file }) => {
    console.log('Sending message:', message);
    console.log('Sheet ID:', sheetId);

    setIsPanelOpen(true);
    setIsLoading(true);
    setUserMessage(message); // Set the user message immediately
    setAiMessage(""); // Clear previous AI message

    try {
      // Call the assistant service with the necessary data
      const data = await sheetAssistantService(sheetId, message, file);
      // Update the aiMessage with the assistant's response
      setAiMessage(data.response);
    } catch (error) {
      console.error("Error fetching assistant response:", error);
      setAiMessage("This feature is coming soon...");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to close the AI panel
  const closePanel = () => {
    setIsPanelOpen(false);
    setAiMessage("");
    setUserMessage("");
  };

  // Find the active sheet based on activeSheetId
  const activeSheet = sheets.find((sheet) => sheet._id === activeSheetId);

  // Memoized function to fetch sheets
  const getSheets = useCallback(async () => {
    try {
      const sheetData = await sheetsGetAllService();
      setSheets(sheetData);
      // If the current active sheet is not present, set the first sheet as active
      if (
        sheetData.length > 0 &&
        !sheetData.some((sheet) => sheet._id === activeSheetId)
      ) {
        setActiveSheetId(sheetData[0]._id);
      }
    } catch (error) {
      console.error("Error fetching sheets:", error);
    }
  }, [activeSheetId]);

  useEffect(() => {
    // Initial fetch without polling
    getSheets();
  }, [getSheets]); // Dependency array includes getSheets

  // Function to handle creating a new sheet
  const handleCreateSheet = async () => {
    try {
      const existingTitles = sheets.map((sheet) => sheet.title);
      let defaultTitle = "Untitled Sheet";
      let count = 1;
      while (existingTitles.includes(defaultTitle)) {
        defaultTitle = `Untitled Sheet ${count}`;
        count += 1;
      }
      const data = await sheetsCreateSheetService(defaultTitle);
      setSheets((prevSheets) => [...prevSheets, data.sheet]);
      setActiveSheetId(data.sheet._id);
      toast.success("✅ New sheet created! Click the title to rename it.");
    } catch (error) {
      console.error("Error creating sheet:", error);
    }
  };

  // Function to handle sheet actions, such as renaming
  const handleSheetAction = (action, payload) => {
    try {
      if (action === "rename") {
        const { newTitle } = payload;
        console.log(`Renaming sheet ${activeSheetId} to "${newTitle}"`);
        setSheets((prevSheets) =>
          prevSheets.map((sheet) =>
            sheet._id === activeSheetId ? { ...sheet, title: newTitle } : sheet
          )
        );
      }
    } catch (error) {
      console.error("Error updating sheet:", error);
    }
  };

  return (
    <div>
      {sheets.length > 0 ? (
        <div>
          <SheetsTabs
            sheets={sheets}
            activeSheetId={activeSheetId}
            setActiveSheetId={setActiveSheetId}
            onCreateSheet={handleCreateSheet}
          />
          <SheetHeader sheet={activeSheet} onSheetUpdate={handleSheetAction} />
          {activeSheet ? (
            <SheetTable data={[activeSheet]} />
          ) : (
            <p>No active sheet selected.</p>
          )}
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-xl mb-4 p-6">
            Your sheets will load here. If your sheets are not showing, try
            refreshing the page. 
            <br/>
            <br/>
             If you don't have any sheets, click below to
            create one.
          </h2>
          <IconButton
            onClick={handleCreateSheet}
            icon={<PlusIcon className="w-5 h-5" />}
            text="Create New Sheet"
          />
        </div>
      )}
      {/* Include AiPanel and AiInput here */}
      <AiPanel
        isOpen={isPanelOpen}
        onClose={closePanel}
        isLoading={isLoading}
        userMessage={userMessage} // Pass the user message
        aiMessage={aiMessage} // Pass the AI message
      />
      {/* Conditionally render AiInput only if activeSheetId is valid */}
      {activeSheetId && (
        <AiInput onSend={handleSend} sheetId={activeSheetId} />
      )}
    </div>
  );
};

export default SheetsWrapper;
