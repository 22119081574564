// src/components/AiPanel.js

import React from "react";
import { X } from "react-feather";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const AiPanel = ({
  isOpen,
  onClose,
  isLoading = false,
  userMessage = "",
  aiMessage = "",
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed bottom-[90px] left-1/2 transform -translate-x-1/2 w-11/12 max-w-6xl bg-white p-6 border border-gray-100 rounded-xl shadow-md h-2/5 flex flex-col">
      {/* Close Button */}
      <button
        onClick={onClose}
        className="self-end text-gray-500 hover:text-gray-700 focus:outline-none"
        aria-label="Close Panel"
      >
        <X size={24} />
      </button>

      {/* Content */}
      <div className="mt-2 flex-grow overflow-y-auto px-0 md:px-4">
        {/* User Message */}
        {userMessage && (
          <div className="mb-4 p-3 bg-gray-50 rounded-md">
            <span className="text-gray-800">{userMessage}</span>
          </div>
        )}

        {/* AI Message or Skeleton Loader */}
        {isLoading ? (
          // Skeleton Loading Bars
          <div className="space-y-4">
            <div className="h-[48px] bg-green-100 rounded w-full animate-pulse"></div>
          </div>
        ) : (
          // AI Message with Markdown
          aiMessage && (
            <div className="mt-2 p-3 bg-green-50 rounded-md text-green-800 prose">
              <ReactMarkdown>{aiMessage}</ReactMarkdown>
            </div>
          )
        )}
      </div>
    </div>
  );
};

AiPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  userMessage: PropTypes.string,
  aiMessage: PropTypes.string,
};

export default AiPanel;
