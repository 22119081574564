import React from "react";
import PaiaLogo from "../assets/paia-logo.svg";

const Welcome = () => {
  // WhatsApp number in international format without '+' sign
  const whatsappNumber = "13165316318";
  // Prefilled message
  const message = "Hey, please send me a new login link.";
  // Encode the message for use in a URL
  const encodedMessage = encodeURIComponent(message);
  // Construct the WhatsApp URL
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-white">
      <div className="mb-8">
        <img src={PaiaLogo} alt="Paia Logo" className="h-10 animate-pulse" />
      </div>
      <div className="text-center">
        <h2 className="text-2xl font-semibold mb-4">Welcome to Paia Sheets</h2>
        <p className="mb-8 text-gray-700">
          Please request a fresh login link from Paia on WhatsApp.
        </p>
        {/* WhatsApp Button */}
        <a
          href={whatsappUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#3D4934] text-white px-6 py-3 rounded-md"
        >
          Open Paia on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default Welcome;