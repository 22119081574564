import React from 'react';

const IconButton = ({ icon, text, onClick }) => {
  return (
    <button
      className="inline-flex items-center px-2 py-1 text-sm font-semibold text-gray-700 hover:text-[#1F242E] border border-gray-300 rounded-md shadow-sm hover:border-gray-500 focus:outline-none bg-white whitespace-nowrap"
      onClick={onClick}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {text}
    </button>
  );
};

export default IconButton;
