// src/modals/AccountSettingsModal.js
import React, { useState, useEffect, useRef } from "react";
import {
  userGetAccountService,
  userUpdateAccountService,
} from "../../services/apiServices.js";

const AccountSettingsContent = ({ onClose }) => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const firstInputRef = useRef(null);

  // Fetch user data when the component mounts
  useEffect(() => {
    fetchUser();
    // Focus the first input for accessibility
    firstInputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = async () => {
    setLoading(true);
    try {
      const data = await userGetAccountService();
      setUserData({
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        email: data.email || "",
        businessName: data.businessName || "",
        phoneNumber: data.phoneNumber || "",
      });
      setError(null);
    } catch (err) {
      setError(err.message || "Failed to load user data.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setUserData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const { firstName, lastName, email, businessName } = userData;
      const updateData = { firstName, lastName, email, businessName };
      console.log("Submitting Update Data:", updateData); // Debugging line
      const response = await userUpdateAccountService(updateData);
      setSuccess("Profile updated successfully.");
      setUserData((prev) => ({
        ...prev,
        ...response.user,
      }));
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.invalidFields
      ) {
        setError(
          `Invalid fields: ${err.response.data.invalidFields.join(", ")}`
        );
      } else {
        setError(err.message || "Failed to update profile.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {!loading && (
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* First Name */}
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              ref={firstInputRef}
              id="firstName"
              type="text"
              name="firstName"
              value={userData.firstName}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {/* Last Name */}
          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              value={userData.lastName}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {/* Business Name */}
          <div>
            <label
              htmlFor="businessName"
              className="block text-sm font-medium text-gray-700"
            >
              Business Name
            </label>
            <input
              id="businessName"
              type="text"
              name="businessName"
              value={userData.businessName}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Optional"
            />
          </div>

          {/* Phone Number (Read-Only) */}
          <div>
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <input
              id="phoneNumber"
              type="text"
              name="phoneNumber"
              value={userData.phoneNumber}
              readOnly
              className="mt-1 block w-full border border-gray-300 rounded-md bg-gray-100 p-2 cursor-not-allowed"
            />
            <p className="text-sm text-gray-500 mt-1">
              To update your phone number, please email us at{" "}
              <a
                href="mailto:info@paia.chat"
                className="text-[#3D4934] hover:underline"
              >
                info@paia.chat
              </a>
            </p>
          </div>

          {/* Submit Button */}
          <div className="flex  justify-between">
            <button
              type="submit"
              disabled={loading}
              className="bg-[#3D4934] w-full mt-4 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2"
            >
              {loading ? "Updating..." : "Update Account"}
            </button>
          </div>
        </form>
      )}

      {loading && <p className="text-gray-600">Loading...</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      {/* Information Section for Account Deletion */}
      <div className="mt-6">
        <hr className="mb-4" />
        <div className="text-center">
          <h3 className="text-lg font-medium text-gray-700">
            Account Deletion
          </h3>
          <p className="text-sm text-gray-500 mt-2">
            If you wish to delete your account, please email us at{" "}
            <a
              href="mailto:info@paia.chat"
              className="text-[#3D4934] hover:underline"
            >
              info@paia.chat
            </a>
            . Our support team will assist you with the process.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsContent;
