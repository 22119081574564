// src/components/modals/SetupIntegrationsContent.js
import React, { useState, useEffect } from "react";
import GoogleSheetsLogo from "../../assets/google-sheets-logo.svg";
import ExcelLogo from "../../assets/excel-logo.svg";
import AirtableLogo from "../../assets/airtable-logo.svg";
import IntegrationButton from "../../components/common/IntegrationButton.js";
import { 
  integrationGoogleSheetConnectService, 
  sheetsGetSheetService,
  integrationGoogleSheetRefreshService // Import the new API service
} from "../../services/apiServices.js";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SetupIntegrationsContent = ({ sheetId }) => {
  const [sheet, setSheet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false); // State for refresh action
  const [connecting, setConnecting] = useState(false); // State for connecting action
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchSheet = async () => {
      try {
        const data = await sheetsGetSheetService(sheetId);
        setSheet(data.sheet);
      } catch (error) {
        console.error("Error fetching sheet data:", error);
        setError("Failed to fetch sheet data.");
      } finally {
        setLoading(false);
      }
    };
    fetchSheet();
  }, [sheetId]);

  const handleRefresh = async () => {
    setRefreshing(true);
    setError(null);
    try {
      // Trigger the refresh action
      await integrationGoogleSheetRefreshService(sheetId);

      // Refetch the updated sheet data
      const updatedData = await sheetsGetSheetService(sheetId);
      setSheet(updatedData.sheet);

      // Open the refreshed Google Sheet in a new tab
      const refreshedGoogleSheetsIntegration = updatedData.sheet.integrations.find(
        (integration) => integration.name === "googleSheets" && integration.isConnected
      );
      const refreshedGoogleSheetUrl = refreshedGoogleSheetsIntegration
        ? `https://docs.google.com/spreadsheets/d/${refreshedGoogleSheetsIntegration.spreadsheetId}`
        : null;

      if (refreshedGoogleSheetUrl) {
        window.open(refreshedGoogleSheetUrl, "_blank");
      } else {
        setError("Google Sheet URL is not available.");
      }

    } catch (err) {
      console.error("Error refreshing integration:", err);
      setError("Failed to refresh integration. Please try again later.");
    } finally {
      setRefreshing(false);
    }
  };

  const handleGoogleSheetsConnect = async () => {
    setConnecting(true);
    setError(null);
    try {
      await integrationGoogleSheetConnectService(sheetId);
      
      // Optionally, refetch the sheet data to update the UI
      const updatedData = await sheetsGetSheetService(sheetId);
      setSheet(updatedData.sheet);
      
      toast.success("Google Sheets successfully connected!");
    } catch (err) {
      console.error("Error connecting Google Sheets:", err);
      setError("Failed to connect Google Sheets. Please try again.");
      toast.error("Failed to connect Google Sheets. Please try again.");
    } finally {
      setConnecting(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  if (!sheet) return <div>Sheet not found.</div>;

  // Find Google Sheets integration details
  const googleSheetsIntegration = sheet.integrations.find(
    (integration) => integration.name === "googleSheets" && integration.isConnected
  );
  const isGoogleSheetsConnected = Boolean(googleSheetsIntegration);
  
  const integrations = [
    {
      label: "Google Sheets",
      logo: GoogleSheetsLogo,
      disabled: connecting || refreshing, // Disable button while connecting or refreshing
      comingSoon: false,
      onClick: handleGoogleSheetsConnect, // Use the new handler
      isConnected: isGoogleSheetsConnected,
      spreadsheetId: googleSheetsIntegration?.spreadsheetId,
    },
    {
      label: "Excel",
      logo: ExcelLogo,
      disabled: true,
      comingSoon: true,
      onClick: null,
    },
    {
      label: "Airtable",
      logo: AirtableLogo,
      disabled: true,
      comingSoon: true,
      onClick: null,
    },
  ];

  return (
    <div className="py-2">
      {/* Include ToastContainer to render toast notifications */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {integrations.map((integration, index) => (
          <div key={index} className="relative flex flex-col items-center">
            <IntegrationButton
              label={integration.label}
              logo={integration.logo}
              disabled={integration.disabled}
              comingSoon={integration.comingSoon}
              onClick={integration.onClick}
            />
            {/* Render "Connected" pill if Google Sheets is connected */}
            {integration.label === "Google Sheets" && integration.isConnected && (
              <div className="mt-2 flex flex-col items-center">
                <button
                  className={`inline-block px-3 py-1 text-xs font-semibold text-green-700 bg-green-100 rounded-full hover:bg-green-200 focus:outline-none ${
                    refreshing ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleRefresh}
                  disabled={refreshing}
                >
                  {refreshing ? "Refreshing..." : "Refresh & View Sheet"}
                </button>
                {error && (
                  <span className="mt-1 text-xs text-center text-red-500">{error}</span>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

SetupIntegrationsContent.propTypes = {
  sheetId: PropTypes.string.isRequired,
};

export default SetupIntegrationsContent;
