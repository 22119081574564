// src/components/sheets/SheetHeader.js
import React, { useState, useEffect } from "react";
import { useModal } from "../../contexts/ModalContext.js";
import InviteCollaboratorsContent from "../modals/InviteCollaboratorsContent.js";
import SetupIntegrationsContent from "../modals/SetupIntegrationsContent.js";
import ExportDataModal from "../modals/ExportDataContent.js";
import DeleteSheetModal from "../modals/DeleteSheetContent.js";
import { sheetUpdateTitleService } from "../../services/apiServices.js";
import PropTypes from "prop-types";

// Import the SVG icons
import { ReactComponent as InviteIcon } from "../../assets/invite.svg";
import { ReactComponent as IntegrationsIcon } from "../../assets/integrations.svg";
import { ReactComponent as ExportIcon } from "../../assets/export.svg";
import { ReactComponent as BinIcon } from "../../assets/bin.svg";
import { ReactComponent as MeatballsIcon } from "../../assets/meatballs.svg"; // Import the meatballs icon

// Import the new IconButton and PopoutMenu components
import IconButton from "../common/IconButton.js";
import PopoutMenu from "../common/PopoutMenu.js"; // Adjust the path as necessary

const SheetHeader = ({ sheet, onSheetUpdate, onSheetDelete }) => {
  const { openModal } = useModal();

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(sheet.title);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setNewTitle(sheet.title);
  }, [sheet.title]);

  const handleSheetDelete = (deletedSheetId) => {
    console.log(`Sheet with ID ${deletedSheetId} has been deleted.`);
    if (onSheetDelete) {
      onSheetDelete(deletedSheetId);
    }
  };

  // Determine if Google Sheets integration is connected
  const googleSheetsIntegration = sheet.integrations.find(
    (integration) => integration.name === "googleSheets" && integration.isConnected
  );
  const isGoogleSheetsConnected = Boolean(googleSheetsIntegration);

  // Define menu items with appropriate onClick handlers using ModalContext
  const menuItems = [
    {
      label: "Invite Collaborators",
      icon: <InviteIcon className="w-5 h-5" />,
      onClick: () =>
        openModal(
          <div className="flex items-center">
            <InviteIcon className="w-6 h-6 mr-2" />
            <h2 className="text-xl font-semibold">Invite Collaborators</h2>
          </div>,
          <InviteCollaboratorsContent
            sheetId={sheet._id}
            sheetTitle={sheet.title}
          />
        ),
    },
    {
      label: "Set up Integrations",
      icon: <IntegrationsIcon className="w-5 h-5" />,
      onClick: () =>
        openModal(
          <div className="flex items-center">
            <IntegrationsIcon className="w-6 h-6 mr-2" />
            <h2 className="text-xl font-semibold">Set up Integrations</h2>
          </div>,
          <SetupIntegrationsContent sheetId={sheet._id} /> // Pass sheetId here
        ),
      isConnected: isGoogleSheetsConnected, // Add this property
      spreadsheetId: googleSheetsIntegration?.spreadsheetId, // Add this property
    },
    {
      label: "Export Data",
      icon: <ExportIcon className="w-5 h-5" />,
      onClick: () =>
        openModal(
          <div className="flex items-center">
            <ExportIcon className="w-6 h-6 mr-2" />
            <h2 className="text-xl font-semibold">Export Data</h2>
          </div>,
          <ExportDataModal sheetId={sheet._id} sheetTitle={sheet.title} />
        ),
    },
    {
      label: "Delete Sheet",
      icon: <BinIcon className="w-5 h-5" />,
      onClick: () =>
        openModal(
          <div className="flex items-center text-red-600">
            <BinIcon className="w-6 h-6 text-red-500 mr-2" />
            <h2 className="text-xl font-semibold">Delete Sheet</h2>
          </div>,
          <DeleteSheetModal
            sheetId={sheet._id}
            sheetTitle={sheet.title}
            onDeleteSuccess={handleSheetDelete}
          />
        ),
    },
  ];

  const handleTitleClick = () => {
    setIsEditingTitle(true);
  };

  const handleTitleSave = async () => {
    setIsEditingTitle(false);
    if (newTitle.trim() !== "" && newTitle !== sheet.title) {
      await handleSaveSheetTitle(newTitle);
    } else {
      setNewTitle(sheet.title);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleTitleSave();
    }
  };

  // Removed onSheetUpdate call from handleInputChange
  const handleInputChange = (e) => {
    const updatedTitle = e.target.value;
    setNewTitle(updatedTitle);
  };

  const handleSaveSheetTitle = async (newTitle) => {
    if (newTitle.trim() === "") {
      setError("Sheet title cannot be empty.");
      return;
    }

    setIsSaving(true);
    try {
      console.log("Attempting to update sheet title:", newTitle);
      const data = await sheetUpdateTitleService(sheet._id, newTitle.trim());
      console.log("API response:", data);

      if (onSheetUpdate) {
        onSheetUpdate("rename", { newTitle: data.sheet.title });
      }
      setError(null);
    } catch (error) {
      console.error("Error updating sheet title:", error);
      setNewTitle(sheet.title);
      if (onSheetUpdate) {
        onSheetUpdate("rename", { newTitle: sheet.title });
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between rounded py-8 px-4 md:p-12 whitespace-nowrap">
      {/* Title and Meatballs Container */}
      <div className="flex flex-row items-center w-full md:w-auto justify-between">
        {/* Title Section */}
        <div className="flex-1">
          {isEditingTitle ? (
            <div className="w-full md:w-auto">
              <input
                type="text"
                value={newTitle}
                onChange={handleInputChange}
                onBlur={handleTitleSave}
                onKeyPress={handleKeyPress}
                autoFocus
                className="text-2xl font-semibold bg-transparent focus:outline-none w-full md:w-auto px-2 rounded-md border border-gray-300"
                placeholder="Enter sheet title"
                disabled={isSaving}
              />
              {isSaving && <p className="text-gray-500 mt-1">Saving...</p>}
              {error && <p className="text-red-500 mt-1">{error}</p>}
            </div>
          ) : (
            <h2
              className="text-2xl font-semibold cursor-pointer"
              onClick={handleTitleClick}
            >
              {newTitle}
            </h2>
          )}
        </div>

        {/* Meatballs Menu for Mobile */}
        <div className="flex md:hidden ml-4">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2 bg-gray-50 rounded-md focus:outline-none"
            aria-label="Menu"
          >
            <MeatballsIcon className="w-6 h-6" />
          </button>
          <PopoutMenu
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            menuItems={menuItems}
          />
        </div>
      </div>

      {/* Button Container - Standard Buttons for Laptop */}
      <div className="hidden md:flex md:space-x-4 mt-4 md:mt-0">
        {menuItems.map((item, index) => (
          <div key={index} className="relative">
            <IconButton
              icon={item.icon}
              text={item.label}
              onClick={item.onClick}
              className="w-auto"
            />
            {item.label === "Set up Integrations" && item.isConnected && (
              <span className="absolute top-[-3px] right-[8px] inline-block w-2 h-2 bg-green-500 rounded-full"></span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

SheetHeader.propTypes = {
  sheet: PropTypes.object.isRequired,
  onSheetUpdate: PropTypes.func,
  onSheetDelete: PropTypes.func,
};

export default SheetHeader;
