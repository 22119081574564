import React, { useState } from "react";
import JSONLogo from "../../assets/json-logo.svg";
import CSVLogo from "../../assets/csv-logo.svg";
import PDFLogo from "../../assets/pdf-logo.svg";
import PaiaLogo from "../../assets/paia-logo.png";
import IntegrationButton from "../../components/common/IntegrationButton.js";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { sheetsGetSheetService } from "../../services/apiServices.js";

// Helper function to flatten nested objects
const flattenObject = (obj, parent = "", res = {}) => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const propName = parent ? `${parent}.${key}` : key;
      if (Array.isArray(obj[key])) {
        // Handle arrays specifically (e.g., Files: [])
        res[propName] = obj[key].length > 0 ? JSON.stringify(obj[key]) : "";
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively flatten nested objects
        flattenObject(obj[key], propName, res);
      } else {
        // Handle primitive values
        res[propName] = obj[key];
      }
    }
  }
  return res;
};

const ExportDataModal = ({ sheetId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Helper function to fetch dataEntries only
  const fetchDataEntries = async () => {
    if (!sheetId) throw new Error("Sheet ID is required for export.");

    const response = await sheetsGetSheetService(sheetId);

    // Debug: Log the entire response object to verify its structure
    console.log("Fetched Sheet Data:", response);

    // Check if the response and sheet are correctly structured
    if (!response || typeof response !== "object" || !response.sheet) {
      console.error("Invalid sheet data received:", response);
      return [];
    }

    const sheet = response.sheet;

    // Check if dataEntries exist and are correctly formatted
    if (!Array.isArray(sheet.dataEntries)) {
      console.error("Sheet dataEntries is not an array or is missing.");
      return [];
    }

    // Debug: Log the retrieved dataEntries to confirm
    console.log("Valid Data Entries Retrieved:", sheet.dataEntries);

    // Return the dataEntries if it exists and is an array
    return sheet.dataEntries;
  };

  // Export to JSON
  const exportToJSON = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const dataEntries = await fetchDataEntries();
      console.log("Data Entries for JSON Export:", dataEntries);

      if (dataEntries.length === 0) {
        throw new Error("No data available to export.");
      }

      const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(dataEntries, null, 2)
      )}`;

      const link = document.createElement("a");
      link.href = jsonString;
      link.download = `sheet-${sheetId}-dataEntries.json`;
      link.click();
    } catch (err) {
      console.error("Error exporting to JSON:", err);
      setError("Failed to export JSON.");
    } finally {
      setIsLoading(false);
    }
  };

  // Export to CSV
  const exportToCSV = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const dataEntries = await fetchDataEntries();

      // Debug: Check the structure of dataEntries before proceeding
      console.log("Data Entries for CSV Export:", dataEntries);

      if (!dataEntries || dataEntries.length === 0) {
        throw new Error("No data available to export.");
      }

      const flattenedData = dataEntries.map((entry) => flattenObject(entry));

      const headers = Object.keys(flattenedData[0] || {});
      const csvRows = [
        headers.join(","), // Header row
        ...flattenedData.map((row) =>
          headers
            .map((header) => {
              const value = row[header];
              return typeof value === "object" && value !== null
                ? `"${JSON.stringify(value).replace(/"/g, '""')}"`
                : `"${String(value).replace(/"/g, '""')}"`;
            })
            .join(",")
        ),
      ];

      const csvString = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
      const link = document.createElement("a");
      link.href = csvString;
      link.download = `sheet-${sheetId}-dataEntries.csv`;
      link.click();
    } catch (err) {
      console.error("Error exporting to CSV:", err);
      setError("Failed to export CSV.");
    } finally {
      setIsLoading(false);
    }
  };

  // Export to PDF
  const exportToPDF = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Step 1: Fetch Data Entries
      let dataEntries = await fetchDataEntries();
      console.log("Original Data Entries for PDF Export:", dataEntries);

      // Remove or comment out the following line
      // dataEntries = dataEntries.slice(1); // Remove the first element

      // Log updated dataEntries to confirm exclusion (optional)
      // console.log("Modified Data Entries (without first item):", dataEntries);

      // Check if data is available for export
      if (dataEntries.length === 0) {
        throw new Error("No data available to export.");
      }

      // Step 2: Flatten the Data
      const flattenedData = dataEntries.map((entry) => flattenObject(entry));

      // Step 3: Extract Table Columns and Rows
      const tableColumn = Object.keys(flattenedData[0] || {});
      const tableRows = flattenedData.map((row) => Object.values(row));

      // Step 4: Create a New PDF Document
      const doc = new jsPDF({
        orientation: "landscape", // Use landscape orientation for more width
        unit: "pt", // Use points (pt) as the unit for precise measurement
        format: "a4", // Set the PDF format to A4 size
      });

      // Load and Add Logo
      const logoPath = `${PaiaLogo}`; // Replace with the actual path to your logo file
      doc.addImage(logoPath, "PNG", 30, 20, 50, 50); // Position the logo at (x=30, y=20), size (50x50)

      // Step 5: Add Business Information
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Paia Sheets", 100, 40); // Business name at (x=100, y=40)
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
      doc.text(
        "Address: 1111B S Governors Ave STE 23090, Dover, DE 19904, USA",
        100,
        55
      ); // Address
      doc.text("Email: info@paia.chat", 100, 70); // Contact details

      // Add title text
      doc.setFontSize(12); // Set title font size
      doc.text("Exported Data Entries", 40, 100); // Add title text below the business info

      // Step 6: Configure and Style the autoTable for Multi-Page Support
      doc.autoTable({
        head: [tableColumn], // Define header columns
        body: tableRows, // Populate table rows with data
        startY: 120, // Start table from y position 120 to avoid overlap with header

        // Styling Options for the Table
        styles: {
          fontSize: 10, // Set the global font size for table cells
          cellPadding: 5, // Add padding within each cell
          overflow: "linebreak", // Handle text overflow by wrapping
        },

        // Header Styles
        headStyles: {
          fillColor: [22, 160, 133], // Background color for headers (RGB value)
          textColor: [255, 255, 255], // Text color in the header (white)
          fontSize: 11, // Font size for header text
          fontStyle: "bold", // Font style for header ('bold' for emphasis)
          halign: "center", // Horizontal alignment ('left', 'right', 'center')
        },

        // Column Width Adjustments to Prevent Overflow
        columnStyles: {
          0: { cellWidth: 70 }, // Set width for column 0 (Files)
        },

        // Theme and Layout Options
        theme: "grid", // Options: 'striped', 'grid', 'plain'
        tableWidth: "auto", // Table width to auto-adjust to the content

        // Enable Multi-Page Table with Page Breaks
        pageBreak: "auto", // Automatically break table onto new pages
        margin: { top: 50, bottom: 20, left: 30, right: 30 }, // Adjust margins for spacing

        // Draw Additional Elements on Each Page (Optional)
        didDrawPage: (data) => {
          // Draw a custom header at the top of every page
          doc.setFontSize(8);
          doc.text(
            `Page ${doc.internal.getCurrentPageInfo().pageNumber}`,
            30,
            20
          );

          // Draw a footer on each page
          doc.setFontSize(10);
          doc.text(
            "Confidential Data - For Internal Use Only",
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - 10,
            { align: "center" }
          );
        },
      });

      // Step 7: Save the Document with the Sheet ID as Filename
      doc.save(`paia-sheet-${sheetId}-dataEntries.pdf`);
    } catch (err) {
      console.error("Error exporting to PDF:", err);
      setError("Failed to export PDF.");
    } finally {
      setIsLoading(false);
    }
  };

  const exports = [
    { label: "JSON", logo: JSONLogo, onClick: exportToJSON },
    { label: "CSV", logo: CSVLogo, onClick: exportToCSV },
    { label: "PDF", logo: PDFLogo, onClick: exportToPDF },
  ];

  return (
    <div className="py-2">
      {isLoading && (
        <div className="mb-4 text-center text-blue-500">Exporting...</div>
      )}
      {error && <div className="mb-4 text-center text-red-500">{error}</div>}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {exports.map((exportOption, index) => (
          <IntegrationButton
            key={index}
            label={exportOption.label}
            logo={exportOption.logo}
            disabled={isLoading}
            onClick={exportOption.onClick}
            className="w-full h-full"
          />
        ))}
        <p className="text-sm text-gray-500 col-span-full text-center">
          CSV is suitable for importing into Excel.
        </p>
      </div>
    </div>
  );
};

export default ExportDataModal;
