// src/modals/UpdateColumnContent.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useModal } from "../../contexts/ModalContext.js";

const UpdateColumnContent = ({ currentName, onRenameSuccess }) => {
  const [newName, setNewName] = useState(currentName);
  const [isRenaming, setIsRenaming] = useState(false);
  const [error, setError] = useState(null);
  const { closeModal } = useModal();

  const handleRename = async () => {
    if (isRenaming) {
      console.log("Rename already in progress.");
      return;
    }

    if (newName.trim() === "") {
      setError("Column name cannot be empty.");
      return;
    }

    if (newName === currentName) {
      closeModal();
      
      return;
    }

    setIsRenaming(true);
    setError(null);

    try {
      await onRenameSuccess(newName);
      closeModal();
    } catch (err) {
      console.error("Error renaming column:", err);
      const errorMessage = err.message || "An unexpected error occurred.";
      setError(errorMessage);
    } finally {
      setIsRenaming(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRename();
    }
  };

  return (
    <div>
      <p className="mb-4">Rename the column "<strong>{currentName}</strong>"</p>
      <input
        type="text"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
        className="w-full border border-gray-300 rounded px-3 py-2 mb-4"
        placeholder="Enter new column name"
      />
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="flex justify-end space-x-4">
        <button
          onClick={closeModal}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          disabled={isRenaming}
        >
          Cancel
        </button>
        <button
          onClick={handleRename}
          className="px-4 py-2 bg-black text-white rounded flex items-center"
          disabled={isRenaming}
        >
          {isRenaming ? (
            <svg
              className="animate-spin h-5 w-5 mr-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          ) : (
            "Rename"
          )}
        </button>
      </div>
    </div>
  );
};

UpdateColumnContent.propTypes = {
  currentName: PropTypes.string.isRequired,
  onRenameSuccess: PropTypes.func.isRequired,
};

export default UpdateColumnContent;
