// src/components/PopoutMenu.js
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Import the icons for account-related menus
import { ReactComponent as AccountIcon } from "../../assets/account.svg";
import { ReactComponent as BillingIcon } from "../../assets/billing.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";

const PopoutMenu = ({ isMenuOpen, setIsMenuOpen, menuItems }) => {
  const menuRef = useRef(null);

  // Handle clicks outside the menu to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, setIsMenuOpen]);

  // Helper function to get the correct predefined icon based on label
  const getPredefinedIcon = (label) => {
    switch (label) {
      case "Account Settings":
        return <AccountIcon className="w-5 h-5 mr-3 inline" />;
      case "Manage Billing":
        return <BillingIcon className="w-5 h-5 mr-3 inline" />;
      case "Logout":
        return <LogoutIcon className="w-5 h-5 mr-3 inline" />;
      default:
        return null;
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      {isMenuOpen && (
        <div className="absolute right-0 mt-2 w-56 bg-white border rounded-md shadow-lg z-20 p-2">
          {menuItems.map((item, index) => {
            // Determine which icon to use: custom or predefined
            const icon = item.icon ? (
              <span className="w-5 h-5 mr-3 inline">{item.icon}</span>
            ) : (
              getPredefinedIcon(item.label)
            );

            if (item.href) {
              return (
                <a
                  key={index}
                  href={item.href}
                  className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100 rounded-md"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {icon}
                  {item.label}
                </a>
              );
            } else if (item.onClick) {
              return (
                <button
                  key={index}
                  onClick={() => {
                    item.onClick();
                    setIsMenuOpen(false);
                  }}
                  className="flex items-center w-full text-left font-medium px-4 py-2 text-gray-800 hover:bg-gray-100 rounded-md"
                >
                  {icon}
                  {item.label}
                </button>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </div>
  );
};

PopoutMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      onClick: PropTypes.func,
      label: PropTypes.string.isRequired,
      icon: PropTypes.element, // Custom icon (optional)
    })
  ).isRequired,
};

export default PopoutMenu;
