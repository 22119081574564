// src/components/common/IntegrationButton.js
import React from "react";
import PropTypes from "prop-types";

const IntegrationButton = ({
  label,
  logo,
  disabled = false,
  comingSoon = false,
  onClick,
}) => {
  return (
    <button
      className={`flex flex-col items-center justify-center border border-gray-300 p-4 rounded-lg transition w-full h-full ${
        disabled
          ? "bg-gray-300 text-gray-700 cursor-not-allowed"
          : "bg-white hover:bg-gray-100"
      }`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      {logo && (
        <img src={logo} alt={`${label} Logo`} className="h-12 w-12 mb-2" />
      )}
      <span className="text-lg font-medium text-center whitespace-nowrap">{label}</span>
      {comingSoon && (
        <span className="text-sm text-gray-500 mt-1">Coming Soon...</span>
      )}
    </button>
  );
};

IntegrationButton.propTypes = {
  label: PropTypes.string.isRequired,
  logo: PropTypes.string,
  disabled: PropTypes.bool,
  comingSoon: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IntegrationButton;
