import validator from "validator";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

let csrfToken = "";

// Fetch CSRF Token
export const getCsrfTokenService = async () => {
  try {
    if (!csrfToken) {
      const response = await fetch(`${API_BASE_URL}/auth-csrf-token`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch CSRF token.");
      }

      const data = await response.json();
      csrfToken = data.csrfToken;
    }
    return csrfToken;
  } catch (error) {
    console.error("Error fetching CSRF token:", error);
    throw error;
  }
};

// Common request function
const makeRequest = async (endpoint, options = {}) => {
  try {
    if (!csrfToken) {
      await getCsrfTokenService();
    }

    const headers = {
      ...options.headers,
      "CSRF-Token": csrfToken,
    };

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers,
      credentials: "include",
    });

    if (response.status === 403) {
      csrfToken = "";
      await getCsrfTokenService();
      headers["CSRF-Token"] = csrfToken;

      const retryResponse = await fetch(`${API_BASE_URL}${endpoint}`, {
        ...options,
        headers,
        credentials: "include",
      });

      return retryResponse;
    }

    return response;
  } catch (error) {
    console.error(`Error in makeRequest to ${endpoint}:`, error);
    throw error;
  }
};

// Authentication Services

export const authLogoutService = async () => {
  try {
    const response = await makeRequest("/auth-logout");
    if (!response.ok) throw new Error("Failed to logout.");
    window.location.href = "/";
  } catch (error) {
    console.error("Error in authLogout:", error);
    throw error;
  }
};

export const authStatusService = async () => {
  try {
    const response = await makeRequest("/auth-status");
    if (!response.ok) throw new Error("Failed to check session.");
    return await response.json();
  } catch (error) {
    console.error("Error in authStatus:", error);
    throw error;
  }
};

export const authVerifyService = async (token) => {
  try {
    const response = await makeRequest("/auth-verify", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });
    if (!response.ok) throw new Error("Failed to verify login link.");
    return await response.json();
  } catch (error) {
    console.error("Error in authVerify:", error);
    throw error;
  }
};


// src/services/apiServices.js

export const collaboratorsInviteService = async (
  sheetId,
  phoneNumbers
) => {
  try {
    const response = await makeRequest("/collaborators-invite", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId, phoneNumbers }), // Removed inviteMessage
    });
    if (!response.ok) throw new Error("Failed to invite collaborators.");
    return await response.json();
  } catch (error) {
    console.error("Error in collaboratorsInviteService:", error);
    throw error;
  }
};


export const collaboratorsUpdateService = async (sheetId, updatedInvitees) => {
  try {
    const response = await makeRequest("/collaborators-update", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId, invitedCollaborators: updatedInvitees }), // Send complete list
    });
    if (!response.ok) throw new Error("Failed to update collaborators.");
    return await response.json();
  } catch (error) {
    console.error("Error in collaboratorsUpdateService:", error);
    throw error;
  }
};

export const loginLinkSendToEmailService = async (email, phoneNumber) => {
  try {
    const response = await makeRequest("/login-link-send-to-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, phoneNumber }),
    });
    if (!response.ok) throw new Error("Failed to send login link.");
    return await response.json();
  } catch (error) {
    console.error("Error in userloginLink:", error);
    throw error;
  }
};

export const sheetAddColumnService = async (sheetId, columnName) => {
  try {
    const response = await makeRequest(`/sheet-add-column`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId, columnName }),
    });
    if (!response.ok) throw new Error("Failed to add column.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetAddColumn:", error);
    throw error;
  }
};

export const sheetDeleteColumnService = async (sheetId, columnName) => {
  try {
    const response = await makeRequest(`/sheet-delete-column`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId, columnName }), // Ensure correct request body format
    });
    if (!response.ok) throw new Error("Failed to delete column.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetDeleteColumn:", error);
    throw error;
  }
};

export const sheetUpdateColumnService = async (
  sheetId,
  oldColumnName,
  newColumnName
) => {
  try {
    const response = await makeRequest(`/sheet-update-column`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId, oldColumnName, newColumnName }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update column.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error in sheetUpdateColumn:", error);
    throw error;
  }
};

export const sheetReorderColumnsService = async (sheetId, newColumns) => {
  try {
    const response = await makeRequest(`/sheet-reorder-columns`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId, newColumns }),
    });
    if (!response.ok) throw new Error("Failed to reorder sheet columns.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetReorderColumnsService:", error);
    throw error;
  }
};

export const sheetUpdateDataService = async (sheetId, updatedEntries) => {
  try {
    const response = await makeRequest(`/sheet-update-data`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId, updatedEntries }),
    });
    if (!response.ok) throw new Error("Failed to update sheet data.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetUpdateData:", error);
    throw error;
  }
};

export const sheetUpdateTitleService = async (sheetId, newTitle) => {
  try {
    const response = await makeRequest(`/sheet-update-title`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId, newTitle }),
    });
    if (!response.ok) throw new Error("Failed to update sheet title.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetUpdateTitle:", error);
    throw error;
  }
};

export const sheetsGetSheetService = async (sheetId) => {
  try {
    const response = await makeRequest(`/sheets-get-sheet`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId }), // Use request body for sheetId
    });
    if (!response.ok) throw new Error("Failed to fetch sheet data.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetsGetSheet:", error);
    throw error;
  }
};

export const sheetsCreateSheetService = async (title) => {
  try {
    const response = await makeRequest("/sheets-create-sheet", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ title }),
    });
    if (!response.ok) throw new Error("Failed to create new sheet.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetsCreateSheet:", error);
    throw error;
  }
};

export const sheetsDeleteSheetService = async (sheetId) => {
  try {
    const response = await makeRequest(`/sheets-delete-sheet`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" }, // Add this line
      body: JSON.stringify({ sheetId }),
    });
    if (!response.ok) throw new Error("Failed to delete sheet.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetsDeleteSheet:", error);
    throw error;
  }
};

export const sheetsGetAllService = async () => {
  try {
    const response = await makeRequest("/sheets-get-all");
    if (!response.ok) throw new Error("Failed to fetch sheets data.");
    return await response.json();
  } catch (error) {
    console.error("Error in sheetsGetSheets:", error);
    throw error;
  }
};

// User Services

export const userGetAccountService = async () => {
  try {
    const response = await makeRequest("/user-get-account");
    if (!response.ok) throw new Error("Failed to fetch user details.");
    return await response.json();
  } catch (error) {
    console.error("Error in userGetAccount:", error);
    throw error;
  }
};

export const userUpdateAccountService = async (updateData) => {
  try {
    const response = await makeRequest("/user-update-account", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ updateData }),
    });
    if (!response.ok) throw new Error("Failed to update user details.");
    return await response.json();
  } catch (error) {
    console.error("Error in userUpdateAccount:", error);
    throw error;
  }
};

export const integrationGoogleSheetConnectService = async (sheetId) => {
  try {
    const response = await makeRequest("/integration-google-sheet-connect", {
      // Updated endpoint
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId }),
    });

    const data = await response.json();

    if (data.authUrl && data.state) {
      // Optionally, store state server-side associated with the user's session
      window.location.href = data.authUrl;
    } else {
      throw new Error("Failed to generate auth URL.");
    }
  } catch (error) {
    console.error("Error initiating Google Sheets integration:", error);
    alert("Failed to connect Google Sheets. Please try again later.");
  }
};

export const integrationGoogleSheetRefreshService = async (sheetId) => {
  try {
    const response = await makeRequest("/integration-google-sheet-refresh", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sheetId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to refresh integration.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in refreshGoogleSheetsIntegration:", error);
    throw error;
  }
};

// src/services/apiServices.js

export const sheetAssistantService = async (sheetId, message, file) => {
  // Validate sheetId
  if (!sheetId || !validator.isMongoId(sheetId)) {
    throw new Error("Invalid sheetId: sheetId is required and must be a valid MongoDB ObjectId.");
  }

  // Validate and sanitize the message
  if (!message || !validator.isLength(message, { min: 1, max: 1000 })) {
    throw new Error("Invalid message: Message is required and must be between 1 and 1000 characters.");
  }

  const sanitizedMessage = validator.escape(message);

  try {
    // Prepare the data to send to the backend
    const formData = new FormData();
    formData.append('sheetId', sheetId);
    formData.append('message', sanitizedMessage);
    if (file) {
      formData.append('file', file);
    }

    // Make the request to the backend
    const response = await makeRequest(`/sheet-assistant`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      // Attempt to parse the error message from the backend
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to get assistant response.');
    }

    return await response.json();
  } catch (error) {
    console.error('Error in sheetAssistantService:', error);
    throw error;
  }
};

export const stripeCreateCheckoutSessionService = async (
  planId,
  collaborators = 0
) => {
  try {
    const response = await makeRequest("/stripe-create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        plan: planId,
        collaborators,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "Failed to create checkout session."
      );
    }

    const data = await response.json();
    return data.sessionId;
  } catch (error) {
    console.error("Error in stripeCreateCheckoutSessionService:", error);
    throw error;
  }
};

export const stripeCreateCustomerPortalSessionService = async () => {
  try {
    const response = await makeRequest(
      "/stripe-create-customer-portal-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "Failed to create Customer Portal session."
      );
    }

    const data = await response.json();
    return data.url;
  } catch (error) {
    console.error("Error in stripeCreateCustomerPortalSessionService:", error);
    throw error;
  }
};

export const stripeGetSubscriptionDetailsService = async () => {
  try {
    const response = await makeRequest("/stripe-get-subscription-details", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "Failed to fetch subscription details."
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in stripeGetSubscriptionDetailsService:", error);
    throw error;
  }
};

export const stripeUpdateSubscriptionFeatureService = async (features) => {
  try {
    const response = await makeRequest("/stripe-update-subscription-feature", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(features),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update subscription.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in stripeUpdateSubscriptionFeatureService:", error);
    throw error;
  }
};
