// src/components/common/AccountConfig.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import PopoutMenu from "./PopoutMenu.js";
import { useModal } from "../../contexts/ModalContext.js";
import AccountSettingsContent from "../modals/AccountSettingsContent.js";
import ManageBillingContent from "../modals/ManageBillingContent.js";
import { authLogoutService } from "../../services/apiServices.js";
import { useNavigate } from "react-router-dom";

import { ReactComponent as AccountIcon } from "../../assets/account.svg";
import { ReactComponent as BillingIcon } from "../../assets/billing.svg";

const AccountConfig = ({ appUser }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setIsMenuOpen(false);
    try {
      await authLogoutService();
      navigate("/", { replace: true });
      // Clean up URL if any query parameters exist
      if (window.location.search) {
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };

  const menuItems = [
    {
      label: "Account Settings",
      onClick: () => {
        openModal(
          <div className="flex items-center">
            <AccountIcon className="w-6 h-6 mr-2" />
            <h2 className="text-xl font-semibold">Account Settings</h2>
          </div>,
          <AccountSettingsContent onClose={closeModal} />
        );
        setIsMenuOpen(false);
      },
    },
    {
      label: "Manage Billing",
      onClick: () => {
        openModal(
          <div className="flex items-center">
            <BillingIcon className="w-6 h-6 mr-2" />
            <h2 className="text-xl font-semibold">Manage Billing</h2>
          </div>,
          <ManageBillingContent onClose={closeModal} />
        );
        setIsMenuOpen(false);
      },
    },
    {
      type: "button",
      label: "Logout",
      onClick: handleLogout,
    },
  ];

  // Compute initials from the user's name
  const getInitials = () => {
    if (appUser && appUser.firstName && appUser.lastName) {
      const firstInitial = appUser.firstName.charAt(0).toUpperCase();
      const lastInitial = appUser.lastName.charAt(0).toUpperCase();
      return `${firstInitial}${lastInitial}`;
    }
    return "..."; // Default icon value if no name is available
  };

  return (
    <div className="relative">
      {/* Profile Icon button */}
      <div className="relative rounded-full border-2 border-[#3D4934] p-[2px]">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="focus:outline-none">
          <div className="flex items-center justify-center bg-[#3D4934] text-white rounded-full h-10 w-10 font-semibold">
            {getInitials()}
          </div>
        </button>
      </div>
      {/* Popout menu */}
      <PopoutMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} menuItems={menuItems} />
    </div>
  );
};

AccountConfig.propTypes = {
  appUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

export default AccountConfig;
