// src/components/AiInput.js

import React, { useState, useRef, useEffect } from "react";
import stars from "../../assets/stars.svg";
import attachments from "../../assets/attachments.svg";
import PropTypes from "prop-types";
import { X } from "react-feather";
import validator from "validator";

const AiInput = ({ onSend, sheetId }) => {
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSend = () => {
    const trimmedMessage = message.trim();
    console.log("Sending message:", trimmedMessage);
    console.log("Sheet ID:", sheetId);
    if (validateInput(trimmedMessage)) {
      onSend({ sheetId, message: trimmedMessage, file: selectedFile });
      setMessage("");
      setError("");
    }
  };

  const validateInput = (msg) => {
    if (!sheetId) {
      setError("Sheet ID is missing.");
      return false;
    }

    if (!msg) {
      setError("Message cannot be empty.");
      return false;
    }

    if (!validator.isLength(msg, { min: 1, max: 1000 })) {
      setError("Message must be between 1 and 1000 characters.");
      return false;
    }

    // Additional sanitization can be performed here if necessary

    setError("");
    return true;
  };

  // const handleUploadClick = (e) => {
  //   e.preventDefault();
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };

  const handleFileSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Define allowed MIME types for validation
      const allowedMimeTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
        "text/markdown",
        "application/json",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
        "image/png",
        "image/jpeg",
        "image/webp",
      ];

      if (!allowedMimeTypes.includes(file.type)) {
        alert("Unsupported file type. Please select a valid file.");
        return;
      }

      setSelectedFile(file);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div>
      <div
        className={`fixed bottom-[20px] left-1/2 transform -translate-x-1/2 w-11/12 max-w-6xl bg-white p-4 h-16 flex items-center rounded-xl shadow-md pr-4 border-2 ${
          isFocused ? "border-[#3D4A34]" : "border border-1 border-gray-100"
        }`}
      >
        {/* Display Selected File */}
        {selectedFile && (
          <div className="absolute bottom-20 left-2 flex items-center bg-gray-200 px-2 py-1 rounded">
            <span className="text-sm truncate max-w-xs">
              {selectedFile.name}
            </span>
            <button
              onClick={handleRemoveFile}
              className="ml-2 text-gray-600 hover:text-gray-800 focus:outline-none"
              aria-label="Remove File"
            >
              <X size={16} />
            </button>
          </div>
        )}
        {/* Hidden File Input */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileSelection}
          accept=".pdf,.doc,.docx,.txt,.md,.json,.pptx,.xlsx,.csv,.png,.jpeg,.jpg,.webp"
        />

        {/* Upload Button */}
        <div className="relative mt-1">
          <button
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            // onClick={handleUploadClick}
            className="text-gray-500 focus:outline-none"
            aria-label="Upload File"
          >
            <img
              src={attachments}
              alt="upload"
              style={{ width: "24px", height: "24px" }}
            />
          </button>
          {/* Tooltip */}
          {showTooltip && (
            <div className="absolute bottom-10 left-[90px] w-[180px] transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2">
              This feature is coming soon...
            </div>
          )}
        </div>

        {/* Input Field */}
        <div className="relative flex-grow mx-4">
          <input
            type="text"
            ref={inputRef}
            className="w-full py-2 focus:outline-none bg-transparent"
            placeholder="Ask me about your data..."
            aria-label="Sheet Input"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          {error && (
            <div className="absolute top-full mt-1 text-red-500 text-xs">
              {error}
            </div>
          )}
        </div>

        {/* Send Button */}
        <button
          onClick={handleSend}
          className={`text-gray-500 hover:text-gray-700 focus:outline-none pl-4 ${
            message.trim() ? "cursor-pointer" : "cursor-not-allowed opacity-50"
          }`}
          aria-label="Send Message"
          disabled={!message.trim()}
        >
          <img
            src={stars}
            alt="Send"
            style={{ width: "24px", height: "24px" }}
          />
        </button>
      </div>
    </div>
  );
};

AiInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  sheetId: PropTypes.string.isRequired,
};

export default AiInput;
