// src/components/ModalWrapper.js

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { X } from 'react-feather';
import PropTypes from 'prop-types';

const ModalWrapper = React.forwardRef(({ isOpen, onClose, title, children }, ref) => {
  // Close modal on ESC key press
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
    }
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  // Handle outside click to close the modal
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 overflow-y-auto py-4"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      onClick={handleOverlayClick} // Close modal when clicking outside
    >
      <div
        className="bg-white rounded-lg shadow-lg w-11/12 md:w-[600px] relative flex flex-col max-h-full sm:max-h-screen"
        onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing it
        ref={ref} // Assign the forwarded ref
        tabIndex="-1" // Make div focusable
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Close Modal"
        >
          <X size={24} />
        </button>

        {/* Modal Header */}
        {title && (
          <div className="px-6 py-4 border-b flex-shrink-0">
            <h2 id="modal-title" className="text-2xl font-semibold">
              {title}
            </h2>
          </div>
        )}

        {/* Modal Content */}
        <div className="px-6 py-4 flex-grow overflow-y-auto">
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
});

ModalWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node, // Accepting node as per the previous fix
  children: PropTypes.node, 
};

ModalWrapper.displayName = 'ModalWrapper'; // Helps with debugging

export default ModalWrapper;
