// src/components/SheetsTabs.js
import React from "react";
import plus from "../../assets/plus.svg";
import PropTypes from "prop-types";

const SheetsTabs = ({
  sheets,
  activeSheetId,
  setActiveSheetId,
  onCreateSheet,
}) => {
  return (
    <div className="flex space-x-4 border-b border-gray-300 px-2 md:px-8 bg-white shadow-sm whitespace-nowrap overflow-x-auto custom-scrollbar overflow-y-hidden">
      {sheets.map((sheet) => (
        <button
          key={sheet._id}
          onClick={() => setActiveSheetId(sheet._id)}
          className={`py-2 px-4 -mb-px border-b-4 font-medium hover:bg-gray-50 ${
            sheet._id === activeSheetId
              ? "border-[#1F242E] text-[#1F242E] hover:bg-white"
              : "border-transparent text-gray-400 hover:text-[#1F242E]"
          }`}
        >
          {sheet.title}
        </button>
      ))}

      {/* New Sheet Button */}
      <button
        onClick={onCreateSheet}
        className="flex-shrink-0 p-2 md:p-2 -mb-px border-b-2 border-transparent text-gray-600 hover:text-[#1F242E] font-medium flex items-center justify-center hover:bg-gray-50"
        aria-label="Create New Sheet"
      >
        <img src={plus} alt="plus" className="w-6 h-6 flex-shrink-0" />
      </button>
    </div>
  );
};

SheetsTabs.propTypes = {
  sheets: PropTypes.array.isRequired,
  activeSheetId: PropTypes.string,
  setActiveSheetId: PropTypes.func.isRequired,
  onCreateSheet: PropTypes.func.isRequired,
};

export default SheetsTabs;
