// src/modals/LoginLinkContent.js
import React, { useState, useEffect } from "react";
import {
  userGetAccountService,
  userUpdateAccountService,
  loginLinkSendToEmailService,
} from "../../services/apiServices.js";

const LoginLinkContent = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Fetch user email on component mount
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const userData = await userGetAccountService();
        if (userData && userData.email) {
          setEmail(userData.email);
          setConfirmEmail(userData.email); // Initialize confirmEmail with fetched email
        }
      } catch (err) {
        console.error("Failed to fetch user email:", err);
        setError("Failed to load user data.");
      }
    };

    fetchUserEmail();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    // Validate that both emails match
    if (email !== confirmEmail) {
      setError("Email addresses do not match.");
      return;
    }

    setLoading(true);

    try {
      // Update email in the database
      await userUpdateAccountService(email);

      // Send the login link to the user's email
      await loginLinkSendToEmailService(email);

      setSuccess("Login link sent successfully! Check your email.");
    } catch (err) {
      console.error("Error sending login link:", err);
      setError("Failed to send the login link. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Optional: Real-time validation for matching emails
  const isEmailMismatch = email !== confirmEmail;

  return (
    <div className="py-4">
      <h2 className="text-xl font-semibold mb-4">
        Send a One-Time Login Link.
      </h2>
      <p className="mb-4 text-gray-700">
        Send a one-time login link to your email to open Paia on your laptop.
      </p>
      <form onSubmit={handleSubmit}>
        {/* Email Address Field */}
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email Address
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`mt-1 block w-full border ${
              isEmailMismatch ? "border-red-500" : "border-gray-300"
            } rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500`}
            placeholder="you@example.com"
            required
          />
        </div>

        {/* Confirm Email Address Field */}
        <div className="mb-4">
          <label
            htmlFor="confirmEmail"
            className="block text-sm font-medium text-gray-700"
          >
            Confirm Email Address
          </label>
          <input
            type="email"
            id="confirmEmail"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            className={`mt-1 block w-full border ${
              isEmailMismatch ? "border-red-500" : "border-gray-300"
            } rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500`}
            placeholder="you@example.com"
            required
          />
          {isEmailMismatch && (
            <p className="text-red-500 text-sm mt-1">
              Email addresses do not match.
            </p>
          )}
        </div>

        {/* Display Error or Success Messages */}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}

        {/* Form Actions */}
        <div className="flex justify-between">
          <button
            type="button"
            onClick={onClose}
            className="mr-4 px-4 py-2 bg-gray-300 rounded-lg"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-[#3D4934] text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2"
            disabled={loading || isEmailMismatch}
          >
            {loading ? "Sending..." : "Send Login Link"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginLinkContent;
