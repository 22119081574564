// src/contexts/ModalContext.js

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import ModalWrapper from "../components/modals/ModalWrapper.js";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: "",
    content: null,
    preventClose: false,
  });

  const openModal = (title, content, preventClose = false) => {
    setModalState({
      isOpen: true,
      title,
      content,
      preventClose, // Store whether the modal should block closure
    });
  };

  const closeModal = () => {
    if (!modalState.preventClose) {
      setModalState({
        isOpen: false,
        title: "",
        content: null,
        preventClose: false,
      });
    }
  };

  const modalRef = useRef();

  // Ensure focus is trapped inside the modal
  useEffect(() => {
    if (modalState.isOpen) {
      modalRef.current?.focus();
    }
  }, [modalState.isOpen]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalState.isOpen && (
        <>
          {/* Overlay to block interaction with other parts of the UI */}
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

          <div className="fixed inset-0 flex justify-center items-center z-50">
            <ModalWrapper
              isOpen={modalState.isOpen}
              onClose={closeModal}
              title={modalState.title}
              ref={modalRef} // Assign ref for focus management
            >
              {modalState.content}
            </ModalWrapper>
          </div>
        </>
      )}
    </ModalContext.Provider>
  );
};
