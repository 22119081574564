// src/modals/DeleteDataModal.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as BinIcon } from "../../assets/bin.svg"; // Assuming you have a bin icon
import { useModal } from "../../contexts/ModalContext.js";
const DeleteDataModal = ({ rowIndex, onDeleteRowSuccess }) => {
  const { closeModal } = useModal();
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    setError(null);

    try {
      // Simulate deletion delay or perform any asynchronous operations if needed
      // For example, if deletion involves an API call, perform it here.
      // Since row deletion is handled locally, we'll proceed immediately.

      // Call the onDeleteRowSuccess callback passed from SheetTable
      await onDeleteRowSuccess(rowIndex);
      // Close the modal
      closeModal();
    } catch (err) {
      console.error("Error deleting row:", err);
      const errorMessage = err.message || "An unexpected error occurred.";
      setError(errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <p className="mb-4">
        Are you sure you want to delete this row? This action cannot be undone.
      </p>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="flex  justify-between space-x-4">
        <button
          onClick={closeModal}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          disabled={isDeleting}
        >
          Cancel
        </button>
        <button
          onClick={handleConfirmDelete}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center"
          disabled={isDeleting}
        >
          {isDeleting ? (
            <svg
              className="animate-spin h-5 w-5 mr-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          ) : (
            <BinIcon className="w-5 h-5 mr-2" />
          )}
          {isDeleting ? "Deleting..." : "Delete"}
        </button>
      </div>
    </div>
  );
};

DeleteDataModal.propTypes = {
  rowIndex: PropTypes.number.isRequired, // Index of the row to delete
  onDeleteRowSuccess: PropTypes.func.isRequired, // Callback function to handle deletion
};

export default DeleteDataModal;
