// src/modals/DataFilesContent.js

import React, { useState } from "react";
import { Download } from "react-feather";
import PropTypes from "prop-types";

// Placeholder image URL for when an image fails to load
const PLACEHOLDER_IMAGE =
  "https://via.placeholder.com/300x200?text=Image+Not+Available";

const DataFilesContent = ({ files }) => {

  // State to track which images failed to load
  const [imageErrorIndices, setImageErrorIndices] = useState({});

  // Function to extract file name from an object
  const extractFileName = (file) => {
    if (!file || !file.fileName) return `file-${Date.now()}`;
    return file.fileName;
  };

  // Function to handle file download
  const handleDownload = async (file) => {
    const { signedUrl, fileName } = file;
    console.log("Signed URL:", signedUrl);

    if (!signedUrl) {
      console.error("Signed URL is undefined.");
      alert("File URL is invalid.");
      return;
    }

    try {
      const response = await fetch(signedUrl, {
        method: "GET",
        headers: {
          // Include any necessary headers here, such as authentication tokens
          // 'Authorization': `Bearer ${yourAuthToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up the object URL
    } catch (error) {
      console.error("Download failed:", error);
      // Optionally, display an error message to the user
      alert("Failed to download the file. Please try again.");
    }
  };

  // Handler for image load error
  const handleImageError = (index) => {
    setImageErrorIndices((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  return (
    <div className="flex flex-col space-y-6 h-full">
      {files && files.length > 0 ? (
        <div className="flex-grow overflow-y-auto">
          <div className="space-y-6">
            {files.map((file, index) => (
              <div key={index} className="relative">
                {/* Download Button */}
                <button
                  onClick={() => handleDownload(file)}
                  className="absolute bottom-2 right-2 text-white bg-gray-600 hover:bg-gray-900 p-2 rounded-full shadow-md focus:outline-none"
                  aria-label={`Download ${extractFileName(file)}`}
                  title="Download File"
                >
                  <Download size={20} />
                </button>

                {/* Displayed Image */}
                <div className="w-full h-100 flex items-center justify-center">
                  <img
                    src={
                      imageErrorIndices[index]
                        ? PLACEHOLDER_IMAGE
                        : file.signedUrl
                    }
                    alt={extractFileName(file)}
                    className="object-contain w-full h-full rounded-md"
                    onError={() => handleImageError(index)}
                    loading="lazy" // Enables lazy loading for better performance
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500">No files available.</p>
      )}
    </div>
  );
};

DataFilesContent.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      signedUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DataFilesContent;
